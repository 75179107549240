import { reactive, computed } from "@vue/composition-api";

export const messageAlert = (root) => {
  const message = reactive({
    snackbar: false,
    text: "",
    color: "",
    timeout: 3000,
  });

  const snackbarPosition = computed(() => {
    switch (root.$vuetify.breakpoint.name) {
      case "xs":
        return "top";
      default:
        return null;
    }
  });

  const showSuccess = (msg) => {
    message.color = "success";
    message.text = msg;
    message.snackbar = true;
  };

  const showError = (msg) => {
    message.color = "error";
    message.text = msg;
    message.snackbar = true;
  };

  return { message, snackbarPosition, showSuccess, showError };
};
