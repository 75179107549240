<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Login form</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <ValidationObserver ref="formValidation">
                  <v-form>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="User"
                      rules="required|min:6"
                    >
                      <v-text-field
                        v-model="user"
                        :error-messages="errors"
                        label="User"
                        prepend-icon="mdi-account"
                        type="text"
                        required
                        @keyup.enter="submit"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Password"
                      rules="required|min:6"
                    >
                      <v-text-field
                        v-model="password"
                        :error-messages="errors"
                        label="Password"
                        prepend-icon="mdi-lock"
                        type="password"
                        required
                        @keyup.enter="submit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="btnLoginActive" @click="submit"
                  >Login</v-btn
                >
              </v-card-actions>
              <v-snackbar
                v-model="message.snackbar"
                :color="message.color"
                :timeout="message.timeout"
                top
              >
                {{ message.text }}
                <template v-slot:action="{ attrs }">
                  <v-btn text v-bind="attrs" @click="message.snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ref } from "@vue/composition-api";
import { useActions } from "vuex-composition-helpers";
import { messageAlert } from "@/services/messageService";

export default {
  name: "Login",
  setup(props, { root }) {
    const user = ref("");
    const password = ref("");
    const btnLoginActive = ref(false);
    const formValidation = ref(null);

    const { message, showError } = messageAlert(root);
    const { login } = useActions(["login"]);

    const submit = () => {
      formValidation.value.validate().then((valid) => {
        if (valid) {
          btnLoginActive.value = true;
          login({ user: user.value, password: password.value })
            .then(() => {
              btnLoginActive.value = false;
              root.$router.push("/admin/dashboard");
            })
            .catch((error) => {
              btnLoginActive.value = false;
              showError(error);
            });
        } else {
          console.log("ERROR");
        }
      });
    };

    return {
      user,
      password,
      btnLoginActive,
      formValidation,
      submit,
      message,
    };
  },
};
</script>
