import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Plugins
import vuetify from "./plugins/vuetify";
import "./plugins/axios.interceptors";
import "./plugins/vee-validate";

// CSS
import "vueditor/dist/style/vueditor.min.css";

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
