import { ref, computed } from "@vue/composition-api";
import { messageAlert } from "@/services/messageService";

export const itemService = (root, state) => {
  const dialog = ref(false);
  const editedIndex = ref(-1);
  const deletedIndex = ref(-1);
  const dialogDeleteItem = ref(false);

  const isPasswordOptional = computed(() => state.editedItem.id);
  const formValidation = ref(null);

  const { message, snackbarPosition, showError, showSuccess } =
    messageAlert(root);

  const createItem = () => {
    state.editedItem = Object.assign({}, state.defaultItem);
    openFormWindow();
  };

  const editItem = (item) => {
    editedIndex.value = state.body.indexOf(item);
    state.editedItem = Object.assign({}, item);
    openFormWindow();
  };

  const deleteItem = (item) => {
    root.$store
      .dispatch(state.actions.deleteItem, item)
      .then(() => {
        state.body.splice(deletedIndex.value, 1);
        deletedIndex.value = -1;
        showSuccess("Registro Eliminado.");
      })
      .catch((error) => {
        showError(error);
      });
  };

  const openDeletedDialog = (item) => {
    deletedIndex.value = state.body.indexOf(item);
    dialogDeleteItem.value = true;
  };

  const closeDeletedDialog = () => {
    deletedIndex.value = -1;
    dialogDeleteItem.value = false;
  };

  const toggleStatus = (action, payload) => {
    root.$store
      .dispatch(action, payload)
      .then(() => {
        showSuccess("Status Cambiado.");
      })
      .catch((error) => {
        showError(error);
      });
  };

  const submit = () => {
    formValidation.value.validate().then((valid) => {
      if (valid) {
        save();
      } else {
        showError("Error al intentar guardar el registro.");
      }
    });
  };

  const save = () => {
    if (state.editedItem.id) {
      root.$store
        .dispatch(state.actions.editItem, state.editedItem)
        .then((response) => {
          Object.assign(state.body[editedIndex.value], response);
          showSuccess("Registro Actualizado.");
          editedIndex.value = -1;
        })
        .catch((error) => {
          showError(error);
        });
    } else {
      root.$store
        .dispatch(state.actions.createItem, state.editedItem)
        .then((response) => {
          state.body.push(response);
          showSuccess("Registro Creado.");
        })
        .catch((error) => {
          showError(error);
        });
    }
    close();
  };

  const saveFirst = () => {
    if (state.editedItem.id) {
      root.$store
        .dispatch(state.actions.editItem, state.editedItem)
        .then((response) => {
          Object.assign(state.body[editedIndex.value], response);
          showSuccess("Registro Actualizado.");
          editedIndex.value = -1;
        })
        .catch((error) => {
          showError(error);
        });
    } else {
      root.$store
        .dispatch(state.actions.createItem, state.editedItem)
        .then((response) => {
          state.body.unshift(response);
          showSuccess("Registro Creado.");
        })
        .catch((error) => {
          showError(error);
        });
    }
    close();
  };

  const close = () => {
    root.$nextTick(() => {
      state.editedItem = Object.assign({}, state.defaultItem);
      formValidation.value.reset();
    });
    closeFormWindow();
  };

  const openFormWindow = () => {
    dialog.value = true;
  };

  const closeFormWindow = () => {
    dialog.value = false;
  };

  return {
    dialog,
    editedIndex,
    deletedIndex,
    dialogDeleteItem,
    isPasswordOptional,
    formValidation,
    message,
    snackbarPosition,
    showError,
    showSuccess,
    createItem,
    editItem,
    deleteItem,
    openDeletedDialog,
    closeDeletedDialog,
    toggleStatus,
    submit,
    save,
    saveFirst,
    close,
    openFormWindow,
    closeFormWindow,
  };
};
