import Vue from "vue";
import VueRouter from "vue-router";

import Cookies from "js-cookie";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/admin",
    component: () => import("@/views/Admin.vue"),
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "users",
        component: () => import("@/views/Users.vue"),
      },
      {
        path: "comentarios",
        component: () => import("@/views/Comentarios.vue"),
      },
      {
        path: "opiniones",
        component: () => import("@/views/Opiniones.vue"),
      },
      {
        path: "envios",
        component: () => import("@/views/Envios.vue"),
      },
      {
        path: "productos",
        component: () => import("@/views/Productos.vue"),
      },
      /*
      {
        path: "productos/:id/galeria",
        component: () => import("@/views/GaleriaProducto.vue"),
      },
      */
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.fullPath.indexOf("/admin") !== -1) {
    let cookie = await Cookies.get("userLogged");
    if (!cookie) {
      next({ name: "Login" });
    }
  }
  next();
});

export default router;
