/* eslint-disable no-useless-catch */
import axios from "axios";

export default {
  state: {
    defaultUser: {
      id: 0,
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      empresa: "",
      email: "",
      telefono: "",
      usuario: "",
      password: "",
      activo: 0,
    },
  },
  actions: {
    async getUsers({ rootState }) {
      try {
        const response = await axios.get(rootState.api.url + "users");
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async createUser({ rootState }, payload) {
      try {
        const response = await axios.post(
          rootState.api.url + "create-user",
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
    async editUser({ rootState }, payload) {
      try {
        const response = await axios.put(
          rootState.api.url + "edit-user?id=" + payload.id,
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
    async deleteUser({ rootState }, payload) {
      try {
        const response = await axios.delete(
          rootState.api.url + "delete-user?id=" + payload.id
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async activateUser({ rootState }, payload) {
      try {
        const response = await axios.put(
          rootState.api.url + "activate-user?id=" + payload.id,
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
  },
};
