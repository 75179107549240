import axios from "axios";

axios.interceptors.request.use(
  function (config) {
    /*
    const auth_token = ``;
    if (auth_token) {
      //config.headers.Authorization = `Bearer ${auth_token}`;
      config.headers.Authorization = `Basic ${process.env.VUE_APP_API_AUTHORIZATION_KEY}`;
    }
    */

    /*
    let except = ["login"];
    let url = config.url.replace(
      `${process.env.VUE_APP_SERVER_URL}${process.env.VUE_APP_BASE_URL}`,
      ""
    );
    if (except.indexOf(url) > -1) {
      return config;
    }
    */

    config.headers.Authorization = `Basic ${process.env.VUE_APP_AUTHORIZATION_KEY}`;

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
