<template>
  <div id="opiniones">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="10">
            <v-card>
              <v-card-title>
                {{ title }}
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer class="d-none d-sm-flex"></v-spacer>

                <!-- Create Item (Desktop) -->
                <v-btn
                  color="primary"
                  dark
                  class="mb-2 d-none d-sm-flex"
                  @click="createItem"
                  >Calificar Producto</v-btn
                >
                <!-- END - Create Item (Desktop) -->
              </v-card-title>
              <v-card-text>
                <!-- Dialogo del Formulario de Opiniones -->
                <v-dialog
                  id="form-opinion"
                  v-model="dialog"
                  persistent
                  max-width="700"
                  eager
                >
                  <v-card>
                    <v-card-title>
                      <span class="headline">Calificar Producto</span>
                    </v-card-title>
                    <v-card-text>
                      <ValidationObserver ref="formValidation">
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.producto"
                                rules="required"
                              >
                                <v-select
                                  v-model="state.editedItem.id_producto"
                                  :error-messages="errors"
                                  :items="state.productosCategorias"
                                  :label="`${state.headersTitle.producto}*`"
                                  prepend-icon="mdi-clipboard"
                                  required
                                ></v-select>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.nombre"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="state.editedItem.nombre"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.nombre}*`"
                                  type="text"
                                  prepend-icon="mdi-label"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.email"
                                rules="required|email"
                              >
                                <v-text-field
                                  v-model="state.editedItem.email"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.email}*`"
                                  type="text"
                                  prepend-icon="mdi-email"
                                  require
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.ciudad"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="state.editedItem.ciudad"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.ciudad}*`"
                                  type="text"
                                  prepend-icon="mdi-earth"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.pais"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="state.editedItem.pais"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.pais}*`"
                                  type="text"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                              <p style="font-size: 16px">Experiencia</p>
                              <v-switch
                                v-model="state.editedItem.experiencia"
                                inset
                              >
                              </v-switch>
                            </v-col>
                            <v-col cols="12">
                              <v-card>
                                <v-card-title>Comentario</v-card-title>
                                <v-card-text>
                                  <div id="editor2"></div>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-container>
                      </ValidationObserver>
                      <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close"
                        >Cerrar</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="submit"
                        >Guardar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- End - Dialogo del Formulario de Opiniones -->

                <!-- Dialogo para eliminar una Calificación -->
                <v-dialog
                  v-if="state.body[deletedIndex]"
                  v-model="dialogDeleteItem"
                  persistent
                  max-width="300"
                >
                  <v-card>
                    <v-card-title class="headline"
                      >Eliminar Opinión</v-card-title
                    >
                    <v-card-text
                      >Estas seguro que deseas eliminar la opinión de
                      <span style="color: red">{{
                        state.body[deletedIndex].nombre
                      }}</span>
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeDeletedDialog"
                        >Cerrar</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItem(state.body[deletedIndex])"
                        >Eliminar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- End - Dialogo para eliminar una Calificación -->

                <!-- Tabla de Opiniones -->
                <v-data-table
                  :headers="headers"
                  :items="state.body"
                  :search="search"
                >
                  <template v-slot:item.experiencia="{ item }">
                    <img :src="experienciaImagen(item.experiencia)" alt="" />
                  </template>
                  <template v-slot:item.publicar="{ item }">
                    <v-switch
                      class="pa-3"
                      v-model="item.publicar"
                      @change="changeStatus(item)"
                    ></v-switch>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="openDeletedDialog(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
                <!-- End - Tabla de Opiniones -->

                <!-- Alertas -->
                <v-snackbar
                  v-model="message.snackbar"
                  :color="message.color"
                  :timeout="message.timeout"
                  :top="snackbarPosition === 'top'"
                >
                  {{ message.text }}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      @click="message.snackbar = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
                <!-- End - Alertas -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Create Item (Mobile) -->
    <v-btn
      bottom
      color="pink"
      dark
      fab
      fixed
      right
      @click="createItem"
      style="margin-bottom: 30px"
      class="d-flex d-sm-none"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <!-- End - Create Item (Mobile) -->
  </div>
</template>

<script>
import { onMounted, ref, reactive, computed } from "@vue/composition-api";
import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useState } = createNamespacedHelpers("opiniones");
import { itemService } from "@/services/itemService";
import { imageService } from "@/services/imageService";
import { createEditor } from "vueditor";

export default {
  name: "Opiniones",
  setup(props, { root }) {
    const state = reactive({
      headersTitle: {
        id: "Id",
        log: "Fecha",
        producto: "Producto",
        nombre: "Nombre",
        email: "Email",
        ciudad: "Ciudad",
        pais: "País",
        experiencia: "Experiencia",
        comentario: "Comentario",
        publicar: "Publicar",
      },
      body: [],
      actions: {
        getData: "getOpiniones",
        getProductosCategorias: "getProductosCategorias",
        createItem: "createOpinion",
        editItem: "editOpinion",
        deleteItem: "deleteOpinion",
        changeStatus: "activateOpinion",
      },
      productosCategorias: [],
      defaultItem: {},
      editedItem: {},
      descripcionItem: null,
    });
    const title = ref("Opiniones del Producto");
    const search = ref("");

    const headers = computed(() => [
      { text: state.headersTitle.log, value: "log" },
      { text: state.headersTitle.producto, value: "producto" },
      { text: state.headersTitle.nombre, value: "nombre" },
      { text: state.headersTitle.ciudad, value: "ciudad" },
      { text: state.headersTitle.pais, value: "pais" },
      { text: "Exp", value: "experiencia" },
      {
        text: state.headersTitle.publicar,
        value: "publicar",
        sortable: false,
      },
      { text: "Acciones", value: "actions", sortable: false },
    ]);

    const { defaultOpinion } = useState(["defaultOpinion"]);

    const { experienciaImagen } = imageService(root);

    const {
      dialog,
      editedIndex,
      deletedIndex,
      dialogDeleteItem,
      message,
      snackbarPosition,
      showError,
      formValidation,
      toggleStatus,
      deleteItem,
      openDeletedDialog,
      closeDeletedDialog,
      saveFirst,
      close,
      openFormWindow,
    } = itemService(root, state);

    onMounted(() => {
      createTextEditor();
    });

    const init = () => {
      state.defaultItem = Object.assign({}, defaultOpinion.value);
      root.$store
        .dispatch(state.actions.getData)
        .then((response) => {
          state.body = response;
        })
        .catch((error) => {
          console.log(error);
        });
      root.$store
        .dispatch(state.actions.getProductosCategorias)
        .then((response) => {
          state.productosCategorias = response;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const createTextEditor = () => {
      state.descripcionItem = createEditor("#editor2", {
        toolbar: [
          "removeFormat",
          "undo",
          "|",
          "element",
          "fontName",
          "fontSize",
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "|",
          "sourceCode",
        ],
        fontName: [
          { val: "arial black" },
          { val: "times new roman" },
          { val: "Courier New" },
        ],
        fontSize: [
          "12px",
          "14px",
          "16px",
          "18px",
          "0.8rem",
          "1.0rem",
          "1.2rem",
          "1.5rem",
          "2.0rem",
        ],
      });
    };

    const createItem = () => {
      state.editedItem = Object.assign({}, state.defaultItem);
      state.descripcionItem.setContent(state.editedItem.comentario);
      openFormWindow();
    };

    const editItem = (item) => {
      editedIndex.value = state.body.indexOf(item);
      state.editedItem = Object.assign({}, item);
      state.descripcionItem.setContent(state.editedItem.comentario);
      openFormWindow();
    };

    const changeStatus = (item) => {
      const index = state.body.indexOf(item);
      const payload = {
        id: item.id,
        publicar: state.body[index].publicar ? 1 : 0,
      };
      toggleStatus(state.actions.changeStatus, payload);
    };

    const submit = () => {
      formValidation.value.validate().then((valid) => {
        if (valid) {
          state.editedItem.comentario = state.descripcionItem.getContent();
          state.editedItem.experiencia = state.editedItem.experiencia ? 1 : 0;
          saveFirst();
        } else {
          showError("Error al intentar guardar el registro.");
        }
      });
    };

    init();

    return {
      state,
      title,
      headers,
      search,
      experienciaImagen,
      dialog,
      editedIndex,
      deletedIndex,
      dialogDeleteItem,
      message,
      snackbarPosition,
      formValidation,
      createItem,
      editItem,
      changeStatus,
      deleteItem,
      openDeletedDialog,
      closeDeletedDialog,
      submit,
      close,
    };
  },
};
</script>
