/* eslint-disable no-useless-catch */
import axios from "axios";

export default {
  state: {},
  actions: {
    async getEnvios({ rootState }) {
      try {
        const response = await axios.get(rootState.api.url + "envios");
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async enviosVendidos({ rootState }, payload) {
      try {
        const response = await axios.put(
          rootState.api.url + "envios-vendidos",
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
    async enviosEnviados({ rootState }, payload) {
      try {
        const response = await axios.put(
          rootState.api.url + "envios-enviados",
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
    async nota({ rootState }, folio) {
      try {
        const response = await axios.get(
          rootState.api.url + "nota?id=" + folio
        );
        return response.data;
      } catch (e) {
        throw e;
      }
    },
  },
};
