/* eslint-disable no-useless-catch */
import axios from "axios";

export default {
  state: {
    defaultComentario: {
      id: 0,
      log: "",
      nombre: "",
      apellido: "",
      ciudad: "",
      pais: "",
      experiencia: 1,
      comentario: "",
      publicar: 0,
    },
  },
  actions: {
    async getComentarios({ rootState }) {
      try {
        const response = await axios.get(rootState.api.url + "comentarios");
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async createComentario({ rootState }, payload) {
      try {
        const response = await axios.post(
          rootState.api.url + "create-comentario",
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
    async editComentario({ rootState }, payload) {
      try {
        const response = await axios.put(
          rootState.api.url + "edit-comentario?id=" + payload.id,
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
    async deleteComentario({ rootState }, payload) {
      try {
        const response = await axios.delete(
          rootState.api.url + "delete-comentario?id=" + payload.id
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async activateComentario({ rootState }, payload) {
      try {
        const response = await axios.put(
          rootState.api.url + "activate-comentario?id=" + payload.id,
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
  },
};
