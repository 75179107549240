var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"envios"}},[_c('v-main',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"10"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-dialog',{attrs:{"id":"orden","persistent":"","max-width":"850","eager":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Orden #"+_vm._s(_vm.notaOrder))])]),_c('v-card-text',[_c('div',{attrs:{"id":"nota-descripcion"}})]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cerrar")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.state.body,"search":_vm.search},scopedSlots:_vm._u([{key:"item.vendido",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"pa-3",on:{"change":function($event){return _vm.changeStatusVendidos(item)}},model:{value:(item.vendido),callback:function ($$v) {_vm.$set(item, "vendido", $$v)},expression:"item.vendido"}})]}},{key:"item.enviado",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"pa-3",on:{"change":function($event){return _vm.changeStatusEnviados(item)}},model:{value:(item.enviado),callback:function ($$v) {_vm.$set(item, "enviado", $$v)},expression:"item.enviado"}})]}},{key:"item.vista",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":(_vm.imgDir + "icon/icon_modal.png"),"alt":""},on:{"click":function($event){return _vm.open(item.folio)}}})]}}])}),_c('v-snackbar',{attrs:{"color":_vm.message.color,"timeout":_vm.message.timeout,"top":_vm.snackbarPosition === 'top'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.message.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.message.snackbar),callback:function ($$v) {_vm.$set(_vm.message, "snackbar", $$v)},expression:"message.snackbar"}},[_vm._v(" "+_vm._s(_vm.message.text)+" ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }