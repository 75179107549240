<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-for="item in items">
          <!-- Menu con items -->
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- Menu -->
          <v-list-item v-else :key="item.text" :to="item.to" link>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- Logout -->
        <v-list-item @click="logout" link>
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Salir </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="indigo" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!--
      <v-btn icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      -->
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-btn @click="logout" icon>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <router-view></router-view>

    <v-footer color="indigo" app>
      <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import { ref } from "@vue/composition-api";
import { useActions } from "vuex-composition-helpers";

export default {
  name: "Admin",
  setup(props, { root }) {
    const title = ref("Administración");
    const drawer = ref(null);
    const items = ref([
      { icon: "mdi-console", text: "Dashboard", to: "/admin/dashboard" },
      { icon: "mdi-contacts", text: "Usuarios", to: "/admin/users" },
      { icon: "mdi-basket", text: "Productos", to: "/admin/productos" },
      {
        icon: "mdi-comment-text-outline",
        text: "Comentarios",
        to: "/admin/comentarios",
      },
      {
        icon: "mdi-star-outline",
        text: "Opiniones del Producto",
        to: "/admin/opiniones",
      },
      { icon: "mdi-send", text: "Envíos", to: "/admin/envios" },
    ]);

    const { getUserLogged } = useActions(["getUserLogged"]);

    const init = () => {
      getUserLogged().then((response) => {
        if (!response.admin) {
          items.value = [
            { icon: "mdi-send", text: "Envíos", to: "/admin/envios" },
          ];
        }
      });
    };

    const logout = () => {
      root.$store.dispatch("logout");
      root.$router.push("/");
    };

    init();

    return {
      title,
      drawer,
      items,
      logout,
    };
  },
};
</script>
