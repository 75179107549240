import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, numeric, min_value } from "vee-validate/dist/rules";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacío.",
});

extend("email", {
  ...email,
  message: "Email must be valid.",
});

extend("numeric", {
  ...numeric,
  message: "{_field_} solo puede contener valores numéricos.",
});

extend("decimal", {
  validate: (value, { decimals = "*", separator = "." } = {}) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    );

    return {
      valid: regex.test(value),
    };
  },
  message: "The {_field_} field must contain only decimal values",
});

extend("min_value", {
  ...min_value,
  message: "{_field_} debe ser mayor a 0.",
});

extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: "{_field_} debe tener al menos {length} caracteres.",
});

extend("isUnique", {
  validate(value, { isNewRecord, items }) {
    var result = true;
    isNewRecord = Number(isNewRecord);
    items.filter((item) => {
      if (isNewRecord === -1 && item.toUpperCase() === value.toUpperCase()) {
        result = false;
        return false;
      }
    });
    return result;
  },
  params: ["isNewRecord", "items"],
  message: "El campo {_field_} debe ser único.",
});
