<template>
  <div id="dashboard">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="10">
            <v-card>
              <v-card-title>
                {{ title }}
              </v-card-title>
              <v-card-text> </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";

export default {
  name: "Dashboard",
  setup() {
    const title = ref("Dashboard");

    return {
      title,
    };
  },
};
</script>
