var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"productos"}},[_c('v-main',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"10"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-btn',{staticClass:"mb-2 d-none d-sm-flex",attrs:{"color":"primary","dark":""},on:{"click":_vm.createItem}},[_vm._v("Crear Producto")])],1),_c('v-card-text',[_c('v-dialog',{attrs:{"persistent":"","max-width":"700","eager":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Descripción del Producto")])]),_c('v-card-text',[_c('ValidationObserver',{ref:"formValidation"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.codigo,"rules":("required|isUnique:" + _vm.editedIndex + "," + _vm.codigosProductos)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.codigo) + "*"),"type":"text","prepend-icon":"mdi-barcode-scan","required":""},model:{value:(_vm.state.editedItem.codigo),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "codigo", $$v)},expression:"state.editedItem.codigo"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.nombre,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.nombre) + "*"),"type":"text","prepend-icon":"mdi-label","required":""},model:{value:(_vm.state.editedItem.nombre),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "nombre", $$v)},expression:"state.editedItem.nombre"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.categoria,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.state.categorias,"label":((_vm.state.headersTitle.categoria) + "*"),"prepend-icon":"mdi-clipboard","required":""},model:{value:(_vm.state.editedItem.tipo),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "tipo", $$v)},expression:"state.editedItem.tipo"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.precio,"rules":"required|decimal|min_value:0.00000001"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.precio) + " (USD)*"),"type":"text","prepend-icon":"mdi-cash-usd","required":""},model:{value:(_vm.state.editedItem.precio),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "precio", $$v)},expression:"state.editedItem.precio"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.retail_precio,"rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.retail_precio) + " (USD)*"),"type":"text","prepend-icon":"mdi-cash-usd","required":""},model:{value:(_vm.state.editedItem.retail_precio),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "retail_precio", $$v)},expression:"state.editedItem.retail_precio"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.precio_eu,"rules":"required|decimal|min_value:0.00000001"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.precio_eu) + " (EUR)*"),"type":"text","prepend-icon":"mdi-cash-usd","required":""},model:{value:(_vm.state.editedItem.precio_eu),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "precio_eu", $$v)},expression:"state.editedItem.precio_eu"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.retail_precio_eu,"rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.retail_precio_eu) + " (EUR)*"),"type":"text","prepend-icon":"mdi-cash-usd","required":""},model:{value:(_vm.state.editedItem.retail_precio_eu),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "retail_precio_eu", $$v)},expression:"state.editedItem.retail_precio_eu"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.peso,"rules":"required|decimal|min_value:0.00000001"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.peso) + " (Kgs.)*"),"type":"text","prepend-icon":"mdi-scale-balance","required":""},model:{value:(_vm.state.editedItem.peso),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "peso", $$v)},expression:"state.editedItem.peso"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.peso_envio,"rules":"required|decimal|min_value:0.00000001"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.peso_envio) + " (Kgs.)*"),"type":"text","prepend-icon":"mdi-scale-balance","required":""},model:{value:(_vm.state.editedItem.peso_envio),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "peso_envio", $$v)},expression:"state.editedItem.peso_envio"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.ancho,"rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.ancho) + " (Mts.)*"),"type":"text","prepend-icon":"mdi-map","required":""},model:{value:(_vm.state.editedItem.ancho),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "ancho", $$v)},expression:"state.editedItem.ancho"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.longitud,"rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.longitud) + " (Mts.)*"),"type":"text","prepend-icon":"mdi-map","required":""},model:{value:(_vm.state.editedItem.longitud),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "longitud", $$v)},expression:"state.editedItem.longitud"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.longitud_global,"rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.longitud_global) + " (Mts.)*"),"type":"text","prepend-icon":"mdi-map","required":""},model:{value:(_vm.state.editedItem.longitud_global),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "longitud_global", $$v)},expression:"state.editedItem.longitud_global"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.soporte,"rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.soporte) + " (Kgs.)*"),"type":"text","prepend-icon":"mdi-scale-balance","required":""},model:{value:(_vm.state.editedItem.soporte),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "soporte", $$v)},expression:"state.editedItem.soporte"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.hanging_distance,"rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.hanging_distance) + " (Mts.)*"),"type":"text","prepend-icon":"mdi-map","required":""},model:{value:(_vm.state.editedItem.hanging_distance),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "hanging_distance", $$v)},expression:"state.editedItem.hanging_distance"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.spread_bar,"rules":"required|decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.spread_bar) + " (Mts.)*"),"type":"text","prepend-icon":"mdi-map","required":""},model:{value:(_vm.state.editedItem.spread_bar),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "spread_bar", $$v)},expression:"state.editedItem.spread_bar"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Descripción")]),_c('v-card-text',[_c('div',{attrs:{"id":"editor2"}})])],1)],1)],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.submit}},[_vm._v("Guardar")])],1)],1)],1),(_vm.state.body[_vm.deletedIndex])?_c('v-dialog',{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.dialogDeleteItem),callback:function ($$v) {_vm.dialogDeleteItem=$$v},expression:"dialogDeleteItem"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Eliminar Producto")]),_c('v-card-text',[_vm._v("Estas seguro que deseas eliminar el producto "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.state.body[_vm.deletedIndex].nombre))]),_vm._v(" ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDeletedDialog}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItem(_vm.state.body[_vm.deletedIndex])}}},[_vm._v("Eliminar")])],1)],1)],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.state.body,"search":_vm.search},scopedSlots:_vm._u([{key:"item.imagen",fn:function(ref){
var item = ref.item;
return [(item.imagen)?_c('img',{attrs:{"src":(_vm.imgDir + "galerias/productos/" + (item.imagen)),"alt":"","width":_vm.imageSizeWidth,"height":_vm.imageSizeHeight}}):_vm._e()]}},{key:"item.publicar",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"pa-3",on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.publicar),callback:function ($$v) {_vm.$set(item, "publicar", $$v)},expression:"item.publicar"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openDeletedDialog(item)}}},[_vm._v(" mdi-delete ")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openGaleria(item)}}},[_vm._v(" mdi-camera ")])],1)],1)],1)]}}])}),_c('v-snackbar',{attrs:{"color":_vm.message.color,"timeout":_vm.message.timeout,"top":_vm.snackbarPosition === 'top'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.message.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.message.snackbar),callback:function ($$v) {_vm.$set(_vm.message, "snackbar", $$v)},expression:"message.snackbar"}},[_vm._v(" "+_vm._s(_vm.message.text)+" ")])],1)],1)],1)],1)],1)],1),_c('v-btn',{staticClass:"d-flex d-sm-none",staticStyle:{"margin-bottom":"30px"},attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":_vm.createItem}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }