<template>
  <div id="productos">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="10">
            <v-card>
              <v-card-title>
                {{ title }}
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer class="d-none d-sm-flex"></v-spacer>

                <!-- Create Item (Desktop) -->
                <v-btn
                  color="primary"
                  dark
                  class="mb-2 d-none d-sm-flex"
                  @click="createItem"
                  >Crear Producto</v-btn
                >
                <!-- END - Create Item (Desktop) -->
              </v-card-title>
              <v-card-text>
                <!-- Dialogo del Formulario de Productos -->
                <v-dialog v-model="dialog" persistent max-width="700" eager>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Descripción del Producto</span>
                    </v-card-title>
                    <v-card-text>
                      <ValidationObserver ref="formValidation">
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.codigo"
                                :rules="`required|isUnique:${editedIndex},${codigosProductos}`"
                              >
                                <v-text-field
                                  v-model="state.editedItem.codigo"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.codigo}*`"
                                  type="text"
                                  prepend-icon="mdi-barcode-scan"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.nombre"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="state.editedItem.nombre"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.nombre}*`"
                                  type="text"
                                  prepend-icon="mdi-label"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.categoria"
                                rules="required"
                              >
                                <v-select
                                  v-model="state.editedItem.tipo"
                                  :error-messages="errors"
                                  :items="state.categorias"
                                  :label="`${state.headersTitle.categoria}*`"
                                  prepend-icon="mdi-clipboard"
                                  required
                                ></v-select>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.precio"
                                rules="required|decimal|min_value:0.00000001"
                              >
                                <v-text-field
                                  v-model="state.editedItem.precio"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.precio} (USD)*`"
                                  type="text"
                                  prepend-icon="mdi-cash-usd"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.retail_precio"
                                rules="required|decimal"
                              >
                                <v-text-field
                                  v-model="state.editedItem.retail_precio"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.retail_precio} (USD)*`"
                                  type="text"
                                  prepend-icon="mdi-cash-usd"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.precio_eu"
                                rules="required|decimal|min_value:0.00000001"
                              >
                                <v-text-field
                                  v-model="state.editedItem.precio_eu"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.precio_eu} (EUR)*`"
                                  type="text"
                                  prepend-icon="mdi-cash-usd"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.retail_precio_eu"
                                rules="required|decimal"
                              >
                                <v-text-field
                                  v-model="state.editedItem.retail_precio_eu"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.retail_precio_eu} (EUR)*`"
                                  type="text"
                                  prepend-icon="mdi-cash-usd"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.peso"
                                rules="required|decimal|min_value:0.00000001"
                              >
                                <v-text-field
                                  v-model="state.editedItem.peso"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.peso} (Kgs.)*`"
                                  type="text"
                                  prepend-icon="mdi-scale-balance"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.peso_envio"
                                rules="required|decimal|min_value:0.00000001"
                              >
                                <v-text-field
                                  v-model="state.editedItem.peso_envio"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.peso_envio} (Kgs.)*`"
                                  type="text"
                                  prepend-icon="mdi-scale-balance"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.ancho"
                                rules="required|decimal"
                              >
                                <v-text-field
                                  v-model="state.editedItem.ancho"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.ancho} (Mts.)*`"
                                  type="text"
                                  prepend-icon="mdi-map"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.longitud"
                                rules="required|decimal"
                              >
                                <v-text-field
                                  v-model="state.editedItem.longitud"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.longitud} (Mts.)*`"
                                  type="text"
                                  prepend-icon="mdi-map"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.longitud_global"
                                rules="required|decimal"
                              >
                                <v-text-field
                                  v-model="state.editedItem.longitud_global"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.longitud_global} (Mts.)*`"
                                  type="text"
                                  prepend-icon="mdi-map"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.soporte"
                                rules="required|decimal"
                              >
                                <v-text-field
                                  v-model="state.editedItem.soporte"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.soporte} (Kgs.)*`"
                                  type="text"
                                  prepend-icon="mdi-scale-balance"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.hanging_distance"
                                rules="required|decimal"
                              >
                                <v-text-field
                                  v-model="state.editedItem.hanging_distance"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.hanging_distance} (Mts.)*`"
                                  type="text"
                                  prepend-icon="mdi-map"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.spread_bar"
                                rules="required|decimal"
                              >
                                <v-text-field
                                  v-model="state.editedItem.spread_bar"
                                  :error-messages="errors"
                                  :label="`${state.headersTitle.spread_bar} (Mts.)*`"
                                  type="text"
                                  prepend-icon="mdi-map"
                                  required
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <!--
                            <v-col cols="12">
                              <ValidationProvider
                                v-slot="{ errors }"
                                :name="state.headersTitle.material_des"
                                rules="required"
                              >
                                <v-select
                                  v-model="state.editedItem.material_des"
                                  :error-messages="errors"
                                  :items="state.materiales"
                                  :label="`${state.headersTitle.material_des}*`"
                                  prepend-icon="mdi-widgets"
                                  required
                                ></v-select>
                              </ValidationProvider>
                            </v-col>
                            -->
                            <v-col cols="12">
                              <v-card>
                                <v-card-title>Descripción</v-card-title>
                                <v-card-text>
                                  <div id="editor2"></div>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-container>
                      </ValidationObserver>
                      <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close"
                        >Cerrar</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="submit"
                        >Guardar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- End - Dialogo del Formulario de Productos -->

                <!-- Dialogo para eliminar un Producto -->
                <v-dialog
                  v-if="state.body[deletedIndex]"
                  v-model="dialogDeleteItem"
                  persistent
                  max-width="300"
                >
                  <v-card>
                    <v-card-title class="headline"
                      >Eliminar Producto</v-card-title
                    >
                    <v-card-text
                      >Estas seguro que deseas eliminar el producto
                      <span style="color: red">{{
                        state.body[deletedIndex].nombre
                      }}</span>
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeDeletedDialog"
                        >Cerrar</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItem(state.body[deletedIndex])"
                        >Eliminar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- End - Dialogo para eliminar un Producto -->

                <!-- Tabla de Productos -->
                <v-data-table
                  :headers="headers"
                  :items="state.body"
                  :search="search"
                >
                  <template v-slot:item.imagen="{ item }">
                    <img
                      v-if="item.imagen"
                      :src="`${imgDir}galerias/productos/${item.imagen}`"
                      alt=""
                      :width="imageSizeWidth"
                      :height="imageSizeHeight"
                      class=""
                    />
                  </template>
                  <!--
                  <template v-slot:item.existencias="{ item }">
                    <v-switch
                      class="pa-3"
                      v-model="item.existencias"
                      @change="changeStatusExistencia(item)"
                    ></v-switch>
                  </template>
                  -->
                  <template v-slot:item.publicar="{ item }">
                    <v-switch
                      class="pa-3"
                      v-model="item.publicar"
                      @change="changeStatus(item)"
                    ></v-switch>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-container>
                      <v-row>
                        <v-col cols="4">
                          <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                          </v-icon>
                        </v-col>
                        <v-col cols="4">
                          <v-icon small @click="openDeletedDialog(item)">
                            mdi-delete
                          </v-icon>
                        </v-col>
                        <v-col cols="4">
                          <v-icon small @click="openGaleria(item)">
                            mdi-camera
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-data-table>
                <!-- End - Tabla de Productos -->

                <!-- Alertas -->
                <v-snackbar
                  v-model="message.snackbar"
                  :color="message.color"
                  :timeout="message.timeout"
                  :top="snackbarPosition === 'top'"
                >
                  {{ message.text }}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      @click="message.snackbar = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
                <!-- End - Alertas -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Create Item (Mobile) -->
    <v-btn
      bottom
      color="pink"
      dark
      fab
      fixed
      right
      @click="createItem"
      style="margin-bottom: 30px"
      class="d-flex d-sm-none"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <!-- End - Create Item (Mobile) -->
  </div>
</template>

<script>
import { onMounted, ref, reactive, computed } from "@vue/composition-api";
import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useState } = createNamespacedHelpers("productos");
import { itemService } from "@/services/itemService";
import { imageService } from "@/services/imageService";
import { createEditor } from "vueditor";

export default {
  name: "Productos",
  setup(props, { root }) {
    const state = reactive({
      headersTitle: {
        id: "Id",
        codigo: "Código",
        nombre: "Nombre",
        categoria: "Categoría",
        peso: "Peso",
        peso_envio: "Peso de Envío",
        ancho: "Ancho",
        longitud: "Longitud",
        longitud_global: "Longitud Global",
        soporte: "Soporte",
        hanging_distance: "Hanging Distance",
        spread_bar: "Spread Bar",
        material_des: "Material",
        descripcion: "Descripción",
        precio: "Precio",
        retail_precio: "Retail Precio",
        precio_eu: "Precio EU",
        retail_precio_eu: "Retail Precio EU",
        imagen: "Imagen",
        existencias: "Existencias",
        publicar: "Publicar",
      },
      body: [],
      actions: {
        getData: "getProductos",
        getCategorias: "getCategorias",
        getMateriales: "getProductosMateriales",
        createItem: "createProducto",
        editItem: "editProducto",
        deleteItem: "deleteProducto",
        changeStatus: "activateProducto",
        changeStatusExistencia: "activateProductoExistencia",
      },
      defaultItem: {},
      editedItem: {},
      categorias: [],
      materiales: [],
      descripcionItem: null,
    });
    const title = ref("Productos");
    const search = ref("");

    const headers = computed(() => [
      { text: state.headersTitle.imagen, value: "imagen" },
      { text: state.headersTitle.codigo, value: "codigo" },
      { text: state.headersTitle.nombre, value: "nombre" },
      { text: state.headersTitle.categoria, value: "categoria" },
      {
        text: state.headersTitle.existencias,
        value: "existencias",
        sortable: false,
      },
      {
        text: state.headersTitle.publicar,
        value: "publicar",
        sortable: false,
      },
      { text: "Acciones", value: "actions", sortable: false, align: "center" },
    ]);

    const codigosProductos = computed(() => {
      const response = [];
      state.body.filter((item) => {
        response.push(item.codigo);
      });
      return response;
    });

    const { defaultProducto } = useState(["defaultProducto"]);

    const { imgDir, imageSizeWidth, imageSizeHeight } = imageService(root);

    const {
      dialog,
      editedIndex,
      deletedIndex,
      dialogDeleteItem,
      message,
      snackbarPosition,
      showError,
      formValidation,
      toggleStatus,
      deleteItem,
      openDeletedDialog,
      closeDeletedDialog,
      save,
      close,
      openFormWindow,
    } = itemService(root, state);

    onMounted(() => {
      createTextEditor();
    });

    const init = () => {
      state.defaultItem = Object.assign({}, defaultProducto.value);
      root.$store
        .dispatch(state.actions.getData)
        .then((response) => {
          state.body = response;
        })
        .catch((error) => {
          console.log(error);
        });
      root.$store
        .dispatch(state.actions.getCategorias)
        .then((response) => {
          state.categorias = response;
        })
        .catch((error) => {
          console.log(error);
        });
      root.$store
        .dispatch(state.actions.getMateriales)
        .then((response) => {
          state.materiales = response;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const createTextEditor = () => {
      state.descripcionItem = createEditor("#editor2", {
        toolbar: [
          "removeFormat",
          "undo",
          "|",
          "element",
          "fontName",
          "fontSize",
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "|",
          "sourceCode",
        ],
        fontName: [
          { val: "arial black" },
          { val: "times new roman" },
          { val: "Courier New" },
        ],
        fontSize: [
          "12px",
          "14px",
          "16px",
          "18px",
          "0.8rem",
          "1.0rem",
          "1.2rem",
          "1.5rem",
          "2.0rem",
        ],
      });
    };

    const createItem = () => {
      state.editedItem = Object.assign({}, state.defaultItem);
      state.descripcionItem.setContent(state.editedItem.descripcion);
      openFormWindow();
    };

    const editItem = (item) => {
      editedIndex.value = state.body.indexOf(item);
      state.editedItem = Object.assign({}, item);
      state.descripcionItem.setContent(state.editedItem.descripcion);
      openFormWindow();
    };

    const changeStatus = (item) => {
      const index = state.body.indexOf(item);
      const payload = {
        id: item.id,
        publicar: state.body[index].publicar ? 1 : 0,
      };
      toggleStatus(state.actions.changeStatus, payload);
    };

    const changeStatusExistencia = (item) => {
      const index = state.body.indexOf(item);
      const payload = {
        id: item.id,
        existencias: state.body[index].existencias ? 1 : 0,
      };
      toggleStatus(state.actions.changeStatusExistencia, payload);
    };

    const submit = () => {
      formValidation.value.validate().then((valid) => {
        if (valid) {
          state.editedItem.descripcion = state.descripcionItem.getContent();
          save();
        } else {
          showError("Error al intentar guardar el registro.");
        }
      });
    };

    const openGaleria = (item) => {
      root.$router.push(`productos/${item.id}/galeria`);
    };

    init();

    return {
      state,
      title,
      headers,
      search,
      imgDir,
      imageSizeWidth,
      imageSizeHeight,
      codigosProductos,
      dialog,
      editedIndex,
      deletedIndex,
      dialogDeleteItem,
      message,
      snackbarPosition,
      formValidation,
      createItem,
      editItem,
      changeStatus,
      changeStatusExistencia,
      deleteItem,
      openDeletedDialog,
      closeDeletedDialog,
      submit,
      close,
      openGaleria,
    };
  },
};
</script>
