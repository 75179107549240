/* eslint-disable no-useless-catch */
import axios from "axios";

export default {
  state: {
    defaultOpinion: {
      id: 0,
      log: "",
      id_producto: null,
      producto: "",
      nombre: "",
      email: "",
      ciudad: "",
      pais: "",
      experiencia: 1,
      comentario: "",
      publicar: 0,
    },
  },
  actions: {
    async getOpiniones({ rootState }) {
      try {
        const response = await axios.get(rootState.api.url + "opiniones");
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async getProductosCategorias({ rootState }) {
      try {
        const response = await axios.get(
          rootState.api.url + "productos-categorias"
        );
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async createOpinion({ rootState }, payload) {
      try {
        const response = await axios.post(
          rootState.api.url + "create-opinion",
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
    async editOpinion({ rootState }, payload) {
      try {
        const response = await axios.put(
          rootState.api.url + "edit-opinion?id=" + payload.id,
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
    async deleteOpinion({ rootState }, payload) {
      try {
        const response = await axios.delete(
          rootState.api.url + "delete-opinion?id=" + payload.id
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async activateOpinion({ rootState }, payload) {
      try {
        const response = await axios.put(
          rootState.api.url + "activate-opinion?id=" + payload.id,
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
  },
};
