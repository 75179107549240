<template>
  <div id="user">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="10">
            <v-card>
              <v-card-title>
                {{ title }}
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer class="d-none d-sm-flex"></v-spacer>

                <!-- Create User (Desktop) -->
                <v-btn
                  color="primary"
                  dark
                  class="mb-2 d-none d-sm-flex"
                  @click="createItem"
                  >Crear Usuario</v-btn
                >
                <!-- END - Create User (Desktop) -->
              </v-card-title>

              <!-- Dialogo del Formulario de Usuario -->
              <v-dialog v-model="dialog" persistent max-width="700">
                <v-card>
                  <v-card-title>
                    <span class="headline">Información del Usuario</span>
                  </v-card-title>
                  <v-card-text>
                    <ValidationObserver ref="formValidation">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :name="state.headersTitle.nombre"
                              rules="required|min:2"
                            >
                              <v-text-field
                                v-model="state.editedItem.nombre"
                                :error-messages="errors"
                                :label="`${state.headersTitle.nombre}*`"
                                prepend-icon="mdi-account-outline"
                                type="text"
                                required
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :name="state.headersTitle.apellido_paterno"
                              rules="required"
                            >
                              <v-text-field
                                v-model="state.editedItem.apellido_paterno"
                                :error-messages="errors"
                                :label="`${state.headersTitle.apellido_paterno}*`"
                                type="text"
                                required
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="state.editedItem.apellido_materno"
                              :label="state.headersTitle.apellido_materno"
                              type="text"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="state.editedItem.empresa"
                              :label="state.headersTitle.empresa"
                              type="text"
                              prepend-icon="mdi-home"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :name="state.headersTitle.email"
                              rules="required|email"
                            >
                              <v-text-field
                                v-model="state.editedItem.email"
                                :error-messages="errors"
                                :label="`${state.headersTitle.email}*`"
                                type="text"
                                prepend-icon="mdi-email"
                                require
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :name="state.headersTitle.telefono"
                              rules="numeric"
                            >
                              <v-text-field
                                v-model="state.editedItem.telefono"
                                :error-messages="errors"
                                :label="state.headersTitle.telefono"
                                type="text"
                                prepend-icon="mdi-cellphone-iphone"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :name="state.headersTitle.usuario"
                              rules="required|min:6"
                            >
                              <v-text-field
                                v-model="state.editedItem.usuario"
                                :error-messages="errors"
                                :label="`${state.headersTitle.usuario}*`"
                                type="text"
                                prepend-icon="mdi-account"
                                required
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <ValidationProvider
                              v-slot="{ errors }"
                              :name="state.headersTitle.password"
                              :rules="`${
                                isPasswordOptional ? '' : 'required'
                              }|min:6`"
                            >
                              <v-text-field
                                v-model="state.editedItem.password"
                                :error-messages="errors"
                                :label="state.headersTitle.password"
                                type="password"
                                prepend-icon="mdi-lock"
                                required
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-container>
                    </ValidationObserver>
                    <small>*indicates required field</small>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"
                      >Cerrar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="submit"
                      >Guardar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- End - Dialogo del Formulario de Usuario -->

              <!-- Dialogo para eliminar un Usuario -->
              <v-dialog
                v-if="state.body[deletedIndex]"
                v-model="dialogDeleteItem"
                persistent
                max-width="300"
              >
                <v-card>
                  <v-card-title class="headline">Eliminar Usuario</v-card-title>
                  <v-card-text
                    >Estas seguro que deseas eliminar al usuario
                    <span style="color: red"
                      >{{ state.body[deletedIndex].nombre }}
                      {{ state.body[deletedIndex].apellido_paterno }}
                      {{ state.body[deletedIndex].apellido_materno }}</span
                    >?</v-card-text
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeDeletedDialog"
                      >Cerrar</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItem(state.body[deletedIndex])"
                      >Eliminar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- End - Dialogo para eliminar un Usuario -->

              <!-- Tabla de Usuarios -->
              <v-data-table
                :headers="headers"
                :items="state.body"
                :search="search"
              >
                <template v-slot:item.nombre="{ item }">
                  {{ item.nombre }} {{ item.apellido_paterno }}
                  {{ item.apellido_materno }}
                </template>
                <template v-slot:item.active="{ item }">
                  <v-switch
                    class="pa-3"
                    v-model="item.activo"
                    @change="changeStatus(item)"
                  ></v-switch>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="openDeletedDialog(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
              <!-- End - Tabla de Usuarios -->

              <!-- Alertas -->
              <v-snackbar
                v-model="message.snackbar"
                :color="message.color"
                :timeout="message.timeout"
                :top="snackbarPosition === 'top'"
              >
                {{ message.text }}
                <template v-slot:action="{ attrs }">
                  <v-btn text v-bind="attrs" @click="message.snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
              <!-- End - Alertas -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Create User (Mobile) -->
    <v-btn
      bottom
      color="pink"
      dark
      fab
      fixed
      right
      @click="createItem"
      style="margin-bottom: 30px"
      class="d-flex d-sm-none"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <!-- End - Create User (Mobile) -->
  </div>
</template>

<script>
import { ref, reactive, computed } from "@vue/composition-api";
import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useState } = createNamespacedHelpers("users");
import { itemService } from "@/services/itemService";

export default {
  name: "Users",
  setup(props, { root }) {
    const state = reactive({
      headersTitle: {
        id: "Id",
        nombre: "Nombre",
        apellido_paterno: "Apellido (Paterno)",
        apellido_materno: "Apellido (Materno)",
        empresa: "Empresa",
        email: "Email",
        telefono: "Teléfono Celular",
        usuario: "Usuario",
        password: "Password",
        activo: "Activo",
      },
      body: [],
      actions: {
        getData: "getUsers",
        createItem: "createUser",
        editItem: "editUser",
        deleteItem: "deleteUser",
        changeStatus: "activateUser",
      },
      defaultItem: {},
      editedItem: {},
    });
    const title = ref("Usuarios");
    const search = ref("");
    const headers = computed(() => [
      { text: state.headersTitle.id, value: "id" },
      { text: state.headersTitle.nombre, value: "nombre" },
      { text: state.headersTitle.empresa, value: "empresa" },
      { text: state.headersTitle.email, value: "email" },
      { text: state.headersTitle.activo, value: "active", sortable: false },
      { text: "Acciones", value: "actions", sortable: false },
    ]);

    const { defaultUser } = useState(["defaultUser"]);

    const {
      dialog,
      deletedIndex,
      dialogDeleteItem,
      isPasswordOptional,
      formValidation,
      message,
      snackbarPosition,
      createItem,
      editItem,
      deleteItem,
      openDeletedDialog,
      closeDeletedDialog,
      toggleStatus,
      submit,
      close,
    } = itemService(root, state);

    const init = () => {
      state.defaultItem = Object.assign({}, defaultUser.value);
      root.$store
        .dispatch(state.actions.getData)
        .then((response) => {
          state.body = response;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const changeStatus = (item) => {
      const index = state.body.indexOf(item);
      const payload = { id: item.id, activo: state.body[index].activo ? 1 : 0 };
      toggleStatus(state.actions.changeStatus, payload);
    };

    init();

    return {
      state,
      title,
      headers,
      search,
      dialog,
      deletedIndex,
      dialogDeleteItem,
      isPasswordOptional,
      formValidation,
      message,
      snackbarPosition,
      createItem,
      editItem,
      deleteItem,
      openDeletedDialog,
      closeDeletedDialog,
      changeStatus,
      submit,
      close,
    };
  },
};
</script>
