<template>
  <div id="envios">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" md="10">
            <v-card>
              <v-card-title>
                {{ title }}
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-card-text>
                <!-- Dialogo -->
                <v-dialog
                  id="orden"
                  v-model="dialog"
                  persistent
                  max-width="850"
                  eager
                >
                  <v-card>
                    <v-card-title>
                      <span class="headline">Orden #{{ notaOrder }}</span>
                    </v-card-title>
                    <v-card-text>
                      <div id="nota-descripcion"></div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close"
                        >Cerrar</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- End - Dialogo -->

                <!-- Tabla de Comentarios -->
                <v-data-table
                  :headers="headers"
                  :items="state.body"
                  :search="search"
                >
                  <template v-slot:item.vendido="{ item }">
                    <v-switch
                      class="pa-3"
                      v-model="item.vendido"
                      @change="changeStatusVendidos(item)"
                    ></v-switch>
                  </template>
                  <template v-slot:item.enviado="{ item }">
                    <v-switch
                      class="pa-3"
                      v-model="item.enviado"
                      @change="changeStatusEnviados(item)"
                    ></v-switch>
                  </template>
                  <template v-slot:item.vista="{ item }">
                    <img
                      :src="`${imgDir}icon/icon_modal.png`"
                      alt=""
                      @click="open(item.folio)"
                      style="cursor: pointer"
                    />
                  </template>
                </v-data-table>
                <!-- End - Tabla de Comentarios -->

                <!-- Alertas -->
                <v-snackbar
                  v-model="message.snackbar"
                  :color="message.color"
                  :timeout="message.timeout"
                  :top="snackbarPosition === 'top'"
                >
                  {{ message.text }}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      @click="message.snackbar = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
                <!-- End - Alertas -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import $ from "jquery";
import { ref, reactive, computed } from "@vue/composition-api";
import { imageService } from "@/services/imageService";
import { itemService } from "@/services/itemService";

export default {
  name: "Envios",
  setup(props, { root }) {
    const state = reactive({
      headersTitle: {
        folio: "Orden",
        fecha: "Fecha",
        vendido: "Vendido",
        enviado: "Enviado",
        vista: "Vista",
      },
      body: [],
      actions: {
        getData: "getEnvios",
        changeStatusVendidos: "enviosVendidos",
        changeStatusEnviados: "enviosEnviados",
        notaOrder: "nota",
      },
    });
    const title = ref("Control de Envíos");
    const search = ref("");
    const headers = computed(() => [
      { text: state.headersTitle.fecha, value: "fecha" },
      { text: state.headersTitle.folio, value: "folio" },
      { text: state.headersTitle.vendido, value: "vendido", sortable: false },
      { text: state.headersTitle.enviado, value: "enviado", sortable: false },
      { text: state.headersTitle.vista, value: "vista", sortable: false },
    ]);

    const notaOrder = ref(0);

    const { imgDir, experienciaImagen } = imageService(root);

    const { dialog, message, snackbarPosition, toggleStatus } = itemService(
      root,
      state
    );

    const init = () => {
      root.$store
        .dispatch(state.actions.getData)
        .then((response) => {
          state.body = response;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const changeStatusVendidos = (item) => {
      const index = state.body.indexOf(item);
      const payload = {
        folio: item.folio,
        vendido: state.body[index].vendido ? 1 : 0,
      };
      toggleStatus(state.actions.changeStatusVendidos, payload);
    };

    const changeStatusEnviados = (item) => {
      const index = state.body.indexOf(item);
      const payload = {
        folio: item.folio,
        enviado: state.body[index].enviado ? 1 : 0,
      };
      toggleStatus(state.actions.changeStatusEnviados, payload);
    };

    const open = (folio) => {
      dialog.value = true;
      root.$store
        .dispatch(state.actions.notaOrder, folio)
        .then((response) => {
          notaOrder.value = response.folio;
          $("#nota-descripcion").html(response.nota);
        })
        .catch((error) => {
          this.showError(error);
        });
    };

    const close = () => {
      dialog.value = false;
      $("#nota-descripcion").html("");
    };

    init();

    return {
      state,
      title,
      headers,
      search,
      dialog,
      notaOrder,
      message,
      snackbarPosition,
      imgDir,
      experienciaImagen,
      changeStatusVendidos,
      changeStatusEnviados,
      open,
      close,
    };
  },
};
</script>
