import { computed } from "@vue/composition-api";

export const imageService = (root) => {
  const imgDir = computed(() => root.$store.state.api.dir_img);

  const experienciaImagen = (exp) => {
    var result = root.$store.state.api.dir_img;
    result += exp ? "icon/accept.png" : "icon/delete.png";
    return result;
  };

  const imageSizeWidth = computed(() => {
    switch (root.$vuetify.breakpoint.name) {
      case "xs":
        return 30;
      case "sm":
        return 50;
      default:
        return 100;
    }
  });

  const imageSizeHeight = computed(() => {
    switch (root.$vuetify.breakpoint.name) {
      case "xs":
        return 30;
      case "sm":
        return 50;
      default:
        return 100;
    }
  });

  const imageSizeWidthCatalogo = computed(() => {
    switch (root.$vuetify.breakpoint.name) {
      case "xs":
        return 200;
      case "sm":
        return 200;
      default:
        return 300;
    }
  });

  const imageSizeHeightCatalogo = computed(() => {
    switch (root.$vuetify.breakpoint.name) {
      case "xs":
        return 130;
      case "sm":
        return 130;
      default:
        return 200;
    }
  });

  return {
    imgDir,
    imageSizeWidth,
    imageSizeHeight,
    imageSizeWidthCatalogo,
    imageSizeHeightCatalogo,
    experienciaImagen,
  };
};
