/* eslint-disable no-useless-catch */
import axios from "axios";

export default {
  state: {
    defaultProducto: {
      id: 0,
      codigo: "",
      nombre: "",
      tipo: null,
      categoria: "",
      peso: 0,
      peso_envio: 0,
      ancho: 0,
      longitud: 0,
      longitud_global: 0,
      soporte: 0,
      hanging_distance: 0,
      spread_bar: 0,
      //material_des: null,
      descripcion: "",
      precio: 0,
      retail_precio: 0,
      precio_eu: 0,
      retail_precio_eu: 0,
      imagen: "",
      //existencias: 1,
      publicar: 0,
    },
  },
  actions: {
    async getProductos({ rootState }) {
      try {
        const response = await axios.get(rootState.api.url + "productos");
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async getProducto({ rootState }, id) {
      try {
        const response = await axios.get(
          rootState.api.url + "producto?id=" + id
        );
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async getCategorias({ rootState }) {
      try {
        const response = await axios.get(rootState.api.url + "categorias");
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async getProductosMateriales({ rootState }) {
      try {
        const response = await axios.get(
          rootState.api.url + "productos-materiales"
        );
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async createProducto({ rootState }, payload) {
      try {
        const response = await axios.post(
          rootState.api.url + "create-producto",
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
    async editProducto({ rootState }, payload) {
      try {
        const response = await axios.put(
          rootState.api.url + "edit-producto?id=" + payload.id,
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
    async deleteProducto({ rootState }, payload) {
      try {
        const response = await axios.delete(
          rootState.api.url + "delete-producto?id=" + payload.id
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async activateProducto({ rootState }, payload) {
      try {
        const response = await axios.put(
          rootState.api.url + "activate-producto?id=" + payload.id,
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
    async activateProductoExistencia({ rootState }, payload) {
      try {
        const response = await axios.put(
          rootState.api.url + "activate-producto-existencia?id=" + payload.id,
          payload
        );
        if (!response.data.status) {
          throw response.data.msg;
        }
        return response.data.item;
      } catch (e) {
        throw e;
      }
    },
  },
};
