/* eslint-disable no-useless-catch */
import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";
import Cookies from "js-cookie";

import UsersModule from "./modules/users";
import ProductosModule from "./modules/productos";
import ComentariosModule from "./modules/comentarios";
import OpinionesModule from "./modules/opiniones";
import EnviosModule from "./modules/envios";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users: UsersModule,
    productos: ProductosModule,
    comentarios: ComentariosModule,
    opiniones: OpinionesModule,
    envios: EnviosModule,
  },
  state: {
    api: {
      url: `${process.env.VUE_APP_SERVER_URL}${process.env.VUE_APP_BASE_URL}`,
      dir_img: `${process.env.VUE_APP_SERVER_URL}${process.env.VUE_APP_BASE_URL_IMAGE}`,
    },
  },
  mutations: {},
  actions: {
    async login({ state, dispatch }, payload) {
      try {
        const response = await axios.post(state.api.url + "login", payload);
        if (!response.data.status) {
          throw "Wrong Username / Password !!!";
        }
        dispatch("setUserLogged", response.data.identity);
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    setUserLogged(context, payload) {
      Cookies.set("userLogged", JSON.stringify(payload));
    },
    async getUserLogged() {
      try {
        let user = await Cookies.get("userLogged");
        return JSON.parse(user);
      } catch (e) {
        throw e;
      }
    },
    logout() {
      Cookies.remove("userLogged");
    },
  },
});
