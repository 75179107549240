var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user"}},[_c('v-main',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"10"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-btn',{staticClass:"mb-2 d-none d-sm-flex",attrs:{"color":"primary","dark":""},on:{"click":_vm.createItem}},[_vm._v("Crear Usuario")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Información del Usuario")])]),_c('v-card-text',[_c('ValidationObserver',{ref:"formValidation"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.nombre,"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.nombre) + "*"),"prepend-icon":"mdi-account-outline","type":"text","required":""},model:{value:(_vm.state.editedItem.nombre),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "nombre", $$v)},expression:"state.editedItem.nombre"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.apellido_paterno,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.apellido_paterno) + "*"),"type":"text","required":""},model:{value:(_vm.state.editedItem.apellido_paterno),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "apellido_paterno", $$v)},expression:"state.editedItem.apellido_paterno"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.state.headersTitle.apellido_materno,"type":"text"},model:{value:(_vm.state.editedItem.apellido_materno),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "apellido_materno", $$v)},expression:"state.editedItem.apellido_materno"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.state.headersTitle.empresa,"type":"text","prepend-icon":"mdi-home"},model:{value:(_vm.state.editedItem.empresa),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "empresa", $$v)},expression:"state.editedItem.empresa"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.email,"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.email) + "*"),"type":"text","prepend-icon":"mdi-email","require":""},model:{value:(_vm.state.editedItem.email),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "email", $$v)},expression:"state.editedItem.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.telefono,"rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.state.headersTitle.telefono,"type":"text","prepend-icon":"mdi-cellphone-iphone"},model:{value:(_vm.state.editedItem.telefono),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "telefono", $$v)},expression:"state.editedItem.telefono"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.usuario,"rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":((_vm.state.headersTitle.usuario) + "*"),"type":"text","prepend-icon":"mdi-account","required":""},model:{value:(_vm.state.editedItem.usuario),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "usuario", $$v)},expression:"state.editedItem.usuario"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.state.headersTitle.password,"rules":((_vm.isPasswordOptional ? '' : 'required') + "|min:6")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.state.headersTitle.password,"type":"password","prepend-icon":"mdi-lock","required":""},model:{value:(_vm.state.editedItem.password),callback:function ($$v) {_vm.$set(_vm.state.editedItem, "password", $$v)},expression:"state.editedItem.password"}})]}}])})],1)],1)],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.submit}},[_vm._v("Guardar")])],1)],1)],1),(_vm.state.body[_vm.deletedIndex])?_c('v-dialog',{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.dialogDeleteItem),callback:function ($$v) {_vm.dialogDeleteItem=$$v},expression:"dialogDeleteItem"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Eliminar Usuario")]),_c('v-card-text',[_vm._v("Estas seguro que deseas eliminar al usuario "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.state.body[_vm.deletedIndex].nombre)+" "+_vm._s(_vm.state.body[_vm.deletedIndex].apellido_paterno)+" "+_vm._s(_vm.state.body[_vm.deletedIndex].apellido_materno))]),_vm._v("?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDeletedDialog}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItem(_vm.state.body[_vm.deletedIndex])}}},[_vm._v("Eliminar")])],1)],1)],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.state.body,"search":_vm.search},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.nombre)+" "+_vm._s(item.apellido_paterno)+" "+_vm._s(item.apellido_materno)+" ")]}},{key:"item.active",fn:function(ref){
                            var item = ref.item;
return [_c('v-switch',{staticClass:"pa-3",on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.activo),callback:function ($$v) {_vm.$set(item, "activo", $$v)},expression:"item.activo"}})]}},{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openDeletedDialog(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('v-snackbar',{attrs:{"color":_vm.message.color,"timeout":_vm.message.timeout,"top":_vm.snackbarPosition === 'top'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.message.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.message.snackbar),callback:function ($$v) {_vm.$set(_vm.message, "snackbar", $$v)},expression:"message.snackbar"}},[_vm._v(" "+_vm._s(_vm.message.text)+" ")])],1)],1)],1)],1)],1),_c('v-btn',{staticClass:"d-flex d-sm-none",staticStyle:{"margin-bottom":"30px"},attrs:{"bottom":"","color":"pink","dark":"","fab":"","fixed":"","right":""},on:{"click":_vm.createItem}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }